import { notification } from "ant-design-vue";
import Cookies from 'js-cookie';
//全屏
export const fullScreen = (e, document) => {
  if (!e) {
    // 取消全屏
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFulldcreen) {
      document.msExitFulldcreen();
    }
  } else {
    // 全屏
    var element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFulldcreen) {
      element.msRequestFulldcreen();
    }
  }
};

export const fields = [
  // "stat_cost", //消耗
  // "cpm_platform", //平均千次展现费用
  // "click_cnt", //广告点击次数
  // "ctr", //广告点击率
  "total_live_pay_order_gpm", //GPM
  // "luban_live_pay_order_gpm", //广告GPM
  // "cpc_platform", //广告点击单价
  // "convert_cnt", //广告转化数（待下线）
  // "convert_rate", //广告转化率（待下线）
  // "cpa_platform", //广告转化成本（待下线）
  "ecp_convert_rate", //转化率
  // "ecp_convert_cnt", //转化数
  // "ecp_cpa_platform", //转化成本
  "live_pay_order_gmv_alias", //累计成交金额
  "luban_live_pay_order_gmv", //广告成交金额
  // "live_pay_order_gmv_roi", //整体ROI
  // "ad_live_prepay_and_pay_order_gmv_roi", //广告ROI
  "live_create_order_count_alias", //整体下单数
  "live_create_order_rate", //整体下单率
  // "luban_live_order_count", //广告下单数
  // "ad_live_create_order_rate", //广告下单率
  "live_pay_order_count_alias", //整体成单数
  // "live_pay_order_rate", //整体成单率
  // "luban_live_pay_order_count", //广告成单数
  // "ad_live_pay_order_rate", //广告成单率
  "live_pay_order_gmv_avg", //直播间平均成单金额
  // "ad_live_pay_order_gmv_avg", //广告平均成单金额
  // "luban_live_prepay_order_count", //广告预售订单数
  "luban_live_prepay_order_gmv", //广告预售订单金额
  // "live_prepay_order_count_alias", //整体预售订单数
  "live_prepay_order_gmv_alias", //整体预售订单金额
  // "live_order_pay_coupon_amount", //成交智能优惠券金额
  "total_live_watch_cnt", //直播间观看人次
  "total_live_follow_cnt", //新增粉丝数
  // "live_watch_one_minute_count", //广告引导超1分钟观看人数
  "total_live_fans_club_join_cnt", //直播间新加团人数
  "live_click_cart_count_alias", //直播间查看购物车次数
  "live_click_product_count_alias", //直播间商品点击次数
  "total_live_comment_cnt", //直播间评论次数
  "total_live_share_cnt", //直播间分享次数
  "total_live_gift_cnt", //直播间打赏次数
  "total_live_gift_amount", //直播间音浪收入
];

export const fieldsKey = {
  stat_cost: "消耗",
  cpm_platform: "平均千次展现费用",
  click_cnt: "广告点击次数",
  ctr: "广告点击率",
  total_live_pay_order_gpm: "GPM",
  luban_live_pay_order_gpm: "广告GPM",
  cpc_platform: "广告点击单价",
  convert_cnt: "广告转化数（待下线）",
  convert_rate: "广告转化率（待下线）",
  cpa_platform: "广告转化成本（待下线）",
  ecp_convert_rate: "转化率",
  ecp_convert_cnt: "转化数",
  ecp_cpa_platform: "转化成本",
  live_pay_order_gmv_alias: "累计成交金额",
  luban_live_pay_order_gmv: "广告成交金额",
  live_pay_order_gmv_roi: "整体ROI",
  ad_live_prepay_and_pay_order_gmv_roi: "广告ROI",
  live_create_order_count_alias: "整体下单数",
  live_create_order_rate: "整体下单率",
  luban_live_order_count: "广告下单数",
  ad_live_create_order_rate: "广告下单率",
  live_pay_order_count_alias: "整体成单数",
  live_pay_order_rate: "整体成单率",
  luban_live_pay_order_count: "广告成单数",
  ad_live_pay_order_rate: "广告成单率",
  live_pay_order_gmv_avg: "直播间平均成单金额",
  ad_live_pay_order_gmv_avg: "广告平均成单金额",
  luban_live_prepay_order_count: "广告预售订单数",
  luban_live_prepay_order_gmv: "广告预售订单金额",
  live_prepay_order_count_alias: "整体预售订单数",
  live_prepay_order_gmv_alias: "整体预售订单金额",
  live_order_pay_coupon_amount: "成交智能优惠券金额",
  total_live_watch_cnt: "直播间观看人次",
  total_live_follow_cnt: "新增粉丝数",
  live_watch_one_minute_count: "广告引导超1分钟观看人数",
  total_live_fans_club_join_cnt: "直播间新加团人数",
  live_click_cart_count_alias: "直播间查看购物车次数",
  live_click_product_count_alias: "直播间商品点击次数",
  total_live_comment_cnt: "直播间评论次数",
  total_live_share_cnt: "直播间分享次数",
  total_live_gift_cnt: "直播间打赏次数",
  total_live_gift_amount: "直播间音浪收入",
};

export const monthDatas = {
  1: "一月",
  2: "二月",
  3: "三月",
  4: "四月",
  5: "五月",
  6: "六月",
  7: "七月",
  8: "八月",
  9: "九月",
  10: "十月",
  11: "十一月",
  12: "十二月",
};

export const liveFlowPerformanceData = {
  activity: "活动页",
  douyin_shopping_center: "抖音商城推荐",
  ecom_ad_third_qc_brand: "付费流量-千川品牌",
  ecom_ad_third_qc_brand_other_bidding: "付费流量-其他竞价广告",
  ecom_ad_third_qc_other_brand: "付费流量-其他品牌广告",
  ecom_other: "其他",
  general_search: "搜索",
  homepage_follow: "关注tab",
  homepage_fresh: "直播自然推荐流量-同城feeds",
  homepage_hot: "直播自然推荐流量-推荐feeds",
  live_merge: "直播自然推荐流量-直播广场",
  live_pc_qian_chuan: "付费流量-千川PC广告",
  live_xiao_dian_qian_chuan: "付费流量-小店随心推 ",
  other_recommend_live: "直播自然推荐流量-其他",
  others_homepage: "个人主页&店铺&橱窗",
  touxi_saas: "直播自然推荐流量-头条西瓜",
  video_to_live: "短视频引流",
};

export const notices = (type, msg) => {
  notification[type]({
    message: "系统提示",
    description: msg,
    placement: "top",
  });
};

export const liveKeyData = {
  live_comment_ucnt_td: "直播间发送评论人数",
  live_comment_cnt_td: "直播间评论次数",
  live_share_cnt_td: "直播间被转发次数",
  live_like_cnt_td: "直播间有点赞次数",
  live_watch_cnt_td: "直播间当日观看次数",
  live_watch_ucnt_td: "直播间当日观看人数",
  live_watch_duration_td: "直播间当日观看总时长",
  per_minute_watch_ucnt_td_max: "直播间最高同时在线人数",
  live_fans_watch_ucnt_td: "粉丝观看人数",
  live_fans_watch_duration_td: "粉丝观看总时长",
  live_start_ts: "直播开始时间",
  live_end_ts: "直播结束时间",
  live_duration_td: "直播时长",
  live_follow_ucnt_td: "直播间内点击关注的人数",
};

export const liveKeyDataUnit = {
  live_comment_ucnt_td: "人",
  live_comment_cnt_td: "次",
  live_share_cnt_td: "次",
  live_like_cnt_td: "赞",
  live_watch_cnt_td: "次",
  live_watch_ucnt_td: "人",
  live_watch_duration_td: "秒",
  per_minute_watch_ucnt_td_max: "人",
  live_fans_watch_ucnt_td: "人",
  live_fans_watch_duration_td: "秒",
  live_start_ts: "",
  live_end_ts: "",
  live_duration_td: "秒",
  live_follow_ucnt_td: "人",
};

//将驼峰转为下划线
export const camelToSnake = (key) => {
  return key.replace(/[A-Z]/g, (match) => "_" + match.toLowerCase());
};



//广告账户数据key
export const adAccountKey = {
  'stat_cost': {
    name: '消耗',
    unit: '元',
  },//消耗
  'show_cnt': {
    name: '展示次数',
    unit: '次',
  },//展示次数
  'ctr': {
    name: '点击率',
    unit: '%',
  },//点击率
  'cpm_platform': {
    name: '平均千次展示费用',
    unit: '元',
  },//平均千次展示费用
  'click_cnt': {
    name: '点击次数',
    unit: '次',
  },//点击次数
  'pay_order_count': {
    name: '直接成交订单数',
    unit: '单',
  },//直接成交订单数
  'pay_order_amount': {
    name: '直接成交金额',
    unit: '元',
  },//直接成交金额
  'prepay_and_pay_order_roi': {
    name: '直接支付roi',
    unit: '',
  },//直接支付roi
  'create_order_count': {
    name: '直接下单订单数',
    unit: '单',
  },//直接下单订单数
  'create_order_amount': {
    name: '直接下单金额',
    unit: '元',
  },//直接下单金额
  'create_order_roi': {
    name: '直接下单roi',
    unit: '',
  },//直接下单roi
  'prepay_order_count': {
    name: '直接下单订单数',
    unit: '单',
  },//直接下单订单数
  'prepay_order_amount': {
    name: '直接预售金额',
    unit: '元',
  },//直接预售金额
  'dy_follow': {
    name: '新增粉丝数',
    unit: '人',
  },//新增粉丝数
  'total_play': {
    name: '播放数',
    unit: '次',
  },//播放数
  'play_duration_3s': {
    name: '3s播放数',
    unit: '次',
  },//3s播放数
  'play_25_feed_break': {
    name: '25%进度播放数',
    unit: '次',
  },//25%进度播放数
  'play_50_feed_break': {
    name: '50%进度播放数',
    unit: '次',
  },//50%进度播放数
  'play_75_feed_break': {
    name: '75%进度播放数',
    unit: '次',
  },//75%进度播放数
  'play_over': {
    name: '播放完成数',
    unit: '次',
  },//播放完成数
  'play_over_rate': {
    name: '完播率',
    unit: '%',
  },//完播率
  'cpc_platform': {
    name: '平均点击单价',
    unit: '元',
  },//平均点击单价
  'deep_convert_cnt': {
    name: '深度转化次数',
    unit: '次',
  },//深度转化次数
  'deep_convert_cost': {
    name: '深度转化成本',
    unit: '元',
  },//深度转化成本
  'deep_convert_rate': {
    name: '深度转化率',
    unit: '%',
  },//深度转化率
  'attribution_convert_cnt': {
    name: '转化数（计费时间）',
    unit: '',
  },//转化数（计费时间）
  'attribution_convert_rate': {
    name: '转化率（计费时间）',
    unit: '%',
  },//转化率（计费时间）
  'attribution_convert_cost': {
    name: '转化成本（计费时间）',
    unit: '元',
  },//转化成本（计费时间）
  'attribution_deep_convert_cnt': {
    name: '深度转化次数（计费时间）',
    unit: '次',
  },//深度转化次数（计费时间
  'attribution_deep_convert_cost': {
    name: '深度转化成本（计费时间）',
    unit: '元',
  },//深度转化成本（计费时间）
  'attribution_deep_convert_rate': {
    name: '深度转化率（计费时间）',
    unit: '%',
  },//深度转化率（计费时间）
  'all_order_create_roi_7days': {
    name: '7日总下单ROI',
    unit: '',
  },//7日总下单ROI
  'all_order_pay_roi_7days': {
    name: '7日总支付ROI',
    unit: '',
  },//7日总支付ROI
  'all_order_pay_count_7days': {
    name: '7日总成交订单',
    unit: '单',
  },//7日总成交订单
  'all_order_pay_gmv_7days': {
    name: '7日总成交金额',
    unit: '元',
  },//7日总成交金额
  'pay_order_cost_per_order': {
    name: '直接成交客单价',
    unit: '元',
  },//直接成交客单价
  'create_order_coupon_amount': {
    name: '下单智能优惠券金额',
    unit: '元',
  },//下单智能优惠券金额
  'pay_order_coupon_amount': {
    name: '成交智能优惠券金额',
    unit: '元',
  },//成交智能优惠券金额
  'indirect_order_create_count_7days': {
    name: '间接下单订单数',
    unit: '单',
  },//间接下单订单数
  'indirect_order_create_gmv_7days': {
    name: '间接下单金额',
    unit: '元',
  },//间接下单金额
  'indirect_order_pay_count_7days': {
    name: '间接成交订单数',
    unit: '单',
  },//间接成交订单数
  'indirect_order_pay_gmv_7days': {
    name: '间接成交金额',
    unit: '元',
  },//间接成交金额
  'indirect_order_prepay_count_7days': {
    name: '间接预售订单数',
    unit: '单',
  },//间接预售订单数
  'indirect_order_prepay_gmv_7days': {
    name: '间接预售金额',
    unit: '元',
  },//间接预售金额
  'qianchuan_first_order_cnt': {
    name: '店铺首单新客人数',
    unit: '人',
  },//店铺首单新客人数
  'qianchuan_first_order_rate': {
    name: '店铺首单新客订单占比',
    unit: '%',
  },//店铺首单新客订单占比
  'qianchuan_first_order_convert_cost': {
    name: '店铺首单新客转化成本',
    unit: '元',
  },//店铺首单新客转化成本
  'qianchuan_first_order_direct_pay_gmv': {
    name: '店铺首单新客直接成交金额',
    unit: '元',
  },//店铺首单新客直接成交金额
  'qianchuan_first_order_direct_pay_order_roi': {
    name: '店铺首单新客直接支付ROI',
    unit: '',
  },//店铺首单新客直接支付ROI
  'qianchuan_first_order_ltv30': {
    name: '店铺首单新客30天累计成交金额',
    unit: '元',
  },//店铺首单新客30天累计成交金额
  'qianchuan_first_order_roi30': {
    name: '店铺首单新客30天累计支付ROI',
    unit: '',
  },//店铺首单新客30天累计支付ROI
  'qianchuan_brand_first_order_rate': {
    name: '品牌首单新客订单占比',
    unit: '%',
  },//品牌首单新客订单占比
  'qianchuan_brand_first_order_convert_cost': {
    name: '品牌首单新客转化成本',
    unit: '元',
  },//品牌首单新客转化成本
  'qianchuan_brand_first_order_ltv30': {
    name: '品牌首单新客30天累计成交金额',
    unit: '元',
  },//品牌首单新客30天累计成交金额
  'qianchuan_brand_first_order_direct_pay_order_roi': {
    name: '品牌首单新客直接支付roi',
    unit: '',
  },//品牌首单新客直接支付roi
  'qianchuan_brand_first_order_roi30': {
    name: '品牌首单新客30天累计支付roi',
    unit: '',
  },//品牌首单新客30天累计支付roi
  'qianchuan_brand_first_order_cnt': {
    name: '品牌首单新客数',
    unit: '人',
  },//品牌首单新客数
  'qianchuan_brand_first_order_direct_pay_gmv': {
    name: '品牌首单新客直接成交金额',
    unit: '元',
  },//品牌首单新客直接成交金额
  'qianchuan_author_first_order_rate': {
    name: '抖音号首单新客订单占比',
    unit: '%',
  },//抖音号首单新客30天累计支付roi
  'qianchuan_author_first_order_roi30': {
    name: '抖音号首单新客30天累计支付roi',
    unit: '',
  },//抖音号首单新客30天累计支付roi
  'qianchuan_author_first_order_cnt': {
    name: '抖音号首单新客数',
    unit: '人',
  },//抖音号首单新客数
  'qianchuan_author_first_order_convert_cost': {
    name: '抖音号首单新客转化成本',
    unit: '元',
  },//抖音号首单新客转化成本
  'qianchuan_author_first_order_direct_pay_gmv': {
    name: '抖音号首单新客直接成交金额',
    unit: '元',
  },//抖音号首单新客直接成交金额
  'qianchuan_author_first_order_direct_pay_order_roi': {
    name: '抖音号首单新客直接支付roi',
    unit: '',
  },//抖音号首单新客直接支付roi
  'qianchuan_author_first_order_ltv30': {
    name: '抖音号首单新客30天累计成交金额',
    unit: '元',
  },//抖音号首单新客30天累计成交金额
  'live_order_settle_amount_14d': {
    name: '14天结算金额',
    unit: '元',
  },//14天结算金额
  'live_order_settle_count_14d': {
    name: '14天结算订单数',
    unit: '单',
  },//14天结算订单数
  'live_order_settle_count_rate_14d': {
    name: '14天结算订单率',
    unit: '%',
  },//14天结算订单率
  'ad_live_order_settle_roi_14d': {
    name: '14天结算roi',
    unit: '',
  },//14天结算roi
  'ad_live_order_settle_cost_14d': {
    name: '14天结算成本',
    unit: '元',
  },//14天结算成本
  'unfinished_estimate_order_gmv': {
    name: '未完结直接预售订单预估金额',
    unit: '元',
  },//未完结直接预售订单预估金额
  'indirect_order_unfinished_estimate_gmv_7days': {
    name: '未完结间接预售订单预估金额',
    unit: '元',
  },//未完结间接预售订单预估金额
}

//广告级数数据key
export const adPlanKey = {
  "stat_cost": {
    name: '消耗',
    unit: '元',
  },//float 消耗
  "show_cnt": {
    name: '展示次数',
    unit: '次',
  },//number 展示次数
  "ctr": {
    name: '点击率',
    unit: '%',
  },//float 点击率
  "cpm_platform": {
    name: '平均千次展示费用',
    unit: '元',
  },//float 平均千次展示费用
  "click_cnt": {
    name: '点击次数',
    unit: '次',
  },//number 点击次数
  "pay_order_count": {
    name: '直接成交订单数',
    unit: '单',
  },//number 直接成交订单数
  "pay_order_amount": {
    name: '直接成交金额',
    unit: '元',
  },//float 直接成交金额
  "prepay_and_pay_order_roi": {
    name: '直接支付roi',
    unit: '',
  },//float 直接支付roi
  "create_order_count": {
    name: '直接下单订单数',
    unit: '单',
  },//number 直接下单订单数
  "create_order_amount": {
    name: '直接下单金额',
    unit: '元',
  },//float 直接下单金额
  "create_order_roi": {
    name: '直接下单roi',
    unit: '',
  },//float 直接下单roi
  "prepay_order_count": {
    name: '直接预售订单数',
    unit: '单',
  },//number 直接预售订单数
  "prepay_order_amount": {
    name: '直接预售金额',
    unit: '元',
  },//float 直接预售金额
  "dy_follow": {
    name: '新增粉丝数',
    unit: '人',
  },//number 新增粉丝数
  "convert_cnt": {
    name: '转化数（待下线）',
    unit: '',
  },//number 转化数（待下线）
  "convert_cost": {
    name: '转化成本（待下线）',
    unit: '元',
  },//float 转化成本（待下线）
  "convert_rate": {
    name: '转化率（待下线）',
    unit: '%',
  },//float 转化率（待下线）
  "ecp_convert_rate": {
    name: '转化率',
    unit: '%',
  },//float 转化率
  "ecp_convert_cnt": {
    name: '转化数',
    unit: '',
  },//number 转化数
  "ecp_cpa_platform": {
    name: '转化成本',
    unit: '元',
  },//float 转化成本
  "dy_share": {
    name: '分享次数',
    unit: '次',
  },//number 分享次数
  "dy_comment": {
    name: '评论次数',
    unit: '次',
  },//number 评论次数
  "dy_like": {
    name: '点赞次数',
    unit: '次',
  },//number 点赞次数
  "live_pay_order_cost_per_order": {
    name: '成交客单价',
    unit: '元',
  },//float 成交客单价
  "luban_live_enter_cnt": {
    name: '直播间观看人次',
    unit: '人',
  },//number 直播间观看人次
  "live_watch_one_minute_count": {
    name: '直播间超过1分钟观看人次',
    unit: '人',
  },//number 直播间超过1分钟观看人次
  "live_fans_club_join_cnt": {
    name: '直播间新加团人次',
    unit: '人',
  },//number 直播间新加团人次
  "luban_live_slidecart_click_cnt": {
    name: '直播间查看购物车次数',
    unit: '次',
  },//number 直播间查看购物车次数
  "luban_live_click_product_cnt": {
    name: '直播间商品点击次数',
    unit: '次',
  },//number 直播间商品点击次数
  "luban_live_comment_cnt": {
    name: '直播间评论次数',
    unit: '次',
  },//number 直播间评论次数
  "luban_live_share_cnt": {
    name: '直播间分享次数',
    unit: '次',
  },//number 直播间分享次数
  "luban_live_gift_cnt": {
    name: '直播间打赏次数',
    unit: '次',
  },//number 直播间打赏次数
  "luban_live_gift_amount": {
    name: '直播间音浪收入',
    unit: '',
  },//float 直播间音浪收入
  "total_play": {
    name: '播放数',
    unit: '次',
  },//number 播放数
  "play_duration_3s": {
    name: '3s播放数',
    unit: '次',
  },//number 3s播放数
  "play_25_feed_break": {
    name: '25%进度播放数',
    unit: '次',
  },//number 25%进度播放数
  "play_50_feed_break": {
    name: '50%进度播放数',
    unit: '次',
  },//number 50%进度播放数
  "play_75_feed_break": {
    name: '75%进度播放数',
    unit: '次',
  },//number 75%进度播放数
  "play_over": {
    name: '播放完成数',
    unit: '次',
  },//number 播放完成数
  "play_over_rate": {
    name: '完播率',
    unit: '%',
  },//float 完播率
  "cpc_platform": {
    name: '平均点击单价',
    unit: '元',
  },//float 平均点击单价
  "deep_convert_cnt": {
    name: '深度转化次数',
    unit: '次',
  },//number 深度转化次数
  "deep_convert_cost": {
    name: '深度转化成本',
    unit: '元',
  },//float 深度转化成本
  "deep_convert_rate": {
    name: '深度转化率',
    unit: '元',
  },//float 深度转化率
  "attribution_convert_cnt": {
    name: '转化数（计费时间）',
    unit: '',
  },//number 转化数（计费时间）
  "attribution_convert_rate": {
    name: '转化率（计费时间）',
    unit: '%',
  },//float 转化率（计费时间）
  "attribution_convert_cost": {
    name: '转化成本（计费时间）',
    unit: '元',
  },//float 转化成本（计费时间）
  "attribution_deep_convert_cnt": {
    name: '深度转化次数（计费时间）',
    unit: '次',
  },//number 深度转化次数（计费时间）
  "attribution_deep_convert_cost": {
    name: '深度转化成本（计费时间）',
    unit: '元',
  },//float 深度转化成本（计费时间）
  "attribution_deep_convert_rate": {
    name: '深度转化率（计费时间）',
    unit: '%',
  },//float 深度转化率（计费时间）
  "all_order_create_roi_7days": {
    name: '7日总下单ROI',
    unit: '',
  },//float 7日总下单ROI
  "all_order_pay_roi_7days": {
    name: '7日总支付ROI',
    unit: '',
  },//float 7日总支付ROI
  "all_order_pay_count_7days": {
    name: '7日总成交订单',
    unit: '单',
  },//number 7日总成交订单
  "all_order_pay_gmv_7days": {
    name: '7日总成交金额',
    unit: '元',
  },//float 7日总成交金额
  "pay_order_cost_per_order": {
    name: '直接成交客单价',
    unit: '元',
  },//float 直接成交客单价
  "create_order_coupon_amount": {
    name: '下单智能优惠券金额',
    unit: '元',
  },//float 下单智能优惠券金额
  "pay_order_coupon_amount": {
    name: '成交智能优惠券金额',
    unit: '元',
  },//float 成交智能优惠券金额
  "indirect_order_create_count_7days": {
    name: '间接下单订单数',
    unit: '单',
  },//number 间接下单订单数
  "indirect_order_create_gmv_7days": {
    name: '间接下单金额',
    unit: '元',
  },//float 间接下单金额
  "indirect_order_pay_count_7days": {
    name: '间接成交订单数',
    unit: '单',
  },//number 间接成交订单数
  "indirect_order_pay_gmv_7days": {
    name: '间接成交金额',
    unit: '元',
  },//float 间接成交金额
  "indirect_order_prepay_count_7days": {
    name: '间接预售订单数',
    unit: '单',
  },//number 间接预售订单数
  "indirect_order_prepay_gmv_7days": {
    name: '间接预售金额',
    unit: '元',
  },//float 间接预售金额
  "qianchuan_first_order_cnt": {
    name: '店铺首单新客人数',
    unit: '人',
  },//number 店铺首单新客人数
  "qianchuan_first_order_rate": {
    name: '店铺首单新客订单占比',
    unit: '%',
  },//float 店铺首单新客订单占比
  "qianchuan_first_order_convert_cost": {
    name: '店铺首单新客转化成本',
    unit: '元',
  },//float 店铺首单新客转化成本
  "qianchuan_first_order_direct_pay_gmv": {
    name: '店铺首单新客直接成交金额',
    unit: '元',
  },//float 店铺首单新客直接成交金额
  "qianchuan_first_order_direct_pay_order_roi": {
    name: '店铺首单新客直接支付ROI',
    unit: '',
  },//float 店铺首单新客直接支付ROI
  "qianchuan_first_order_ltv30": {
    name: '店铺首单新客30天累计成交金额',
    unit: '元',
  },//float 店铺首单新客30天累计成交金额
  "qianchuan_first_order_roi30": {
    name: '店铺首单新客30天累计支付ROI',
    unit: '',
  },//floa 店铺首单新客30天累计支付ROI
  "qianchuan_brand_first_order_rate": {
    name: '品牌首单新客订单占比',
    unit: '%',
  },//float 品牌首单新客订单占比
  "qianchuan_brand_first_order_convert_cost": {
    name: '品牌首单新客转化成本',
    unit: '元',
  },//float 品牌首单新客转化成本
  "qianchuan_brand_first_order_ltv30": {
    name: '品牌首单新客30天累计成交金额',
    unit: '元',
  },//float 品牌首单新客30天累计成交金额
  "qianchuan_brand_first_order_direct_pay_order_roi": {
    name: '品牌首单新客直接支付roi',
    unit: '',
  },//float 品牌首单新客直接支付roi
  "qianchuan_brand_first_order_roi30": {
    name: '品牌首单新客30天累计支付roi',
    unit: '',
  },//float 品牌首单新客30天累计支付roi
  "qianchuan_brand_first_order_cnt": {
    name: '品牌首单新客数',
    unit: '人',
  },//number 品牌首单新客数
  "qianchuan_brand_first_order_direct_pay_gmv": {
    name: '品牌首单新客直接成交金额',
    unit: '元',
  },//float 品牌首单新客直接成交金额
  "qianchuan_author_first_order_rate": {
    name: '抖音号首单新客订单占比',
    unit: '%',
  },//float 抖音号首单新客订单占比
  "qianchuan_author_first_order_roi30": {
    name: '抖音号首单新客30天累计支付roi',
    unit: '',
  },//float 抖音号首单新客30天累计支付roi
  "qianchuan_author_first_order_cnt": {
    name: '抖音号首单新客数',
    unit: '人',
  },//number 抖音号首单新客数
  "qianchuan_author_first_order_convert_cost": {
    name: '抖音号首单新客转化成本',
    unit: '元',
  },//float 抖音号首单新客转化成本
  "qianchuan_author_first_order_direct_pay_gmv": {
    name: '抖音号首单新客直接成交金额',
    unit: '元',
  },//float 抖音号首单新客直接成交金额
  "qianchuan_author_first_order_direct_pay_order_roi": {
    name: '抖音号首单新客直接支付roi',
    unit: '',
  },//float 抖音号首单新客直接支付roi
  "qianchuan_author_first_order_ltv30": {
    name: '抖音号首单新客30天累计成交金额',
    unit: '元',
  },//float 抖音号首单新客30天累计成交金额
  'live_order_settle_amount_14d': {
    name: '14天结算金额',
    unit: '元'
  },//14天结算金额
  'live_order_settle_count_14d': {
    name: '14天结算订单数',
    unit: '单'
  },//14天结算订单数
  'live_order_settle_count_rate_14d': {
    name: '14天结算订单率',
    unit: '%'
  },//14天结算订单率
  'ad_live_order_settle_roi_14d': {
    name: '14天结算roi',
    unit: ''
  },//14天结算roi
  'ad_live_order_settle_cost_14d': {
    name: '14天结算成本',
    unit: '元'
  },//14天结算成本
  "unfinished_estimate_order_gmv": {
    name: '未完结直接预售订单预估金额',
    unit: '元',
  },//float 未完结直接预售订单预估金额
  "indirect_order_unfinished_estimate_gmv_7days": {
    name: '未完结间接预售订单预估金额',
    unit: '元',
  },//float 未完结间接预售订单预估金额
}

//广告创意数据
export const adCyKey = {
  "stat_cost": {
    name: '消耗',
    unit: '元',
  },//float 消耗
  "show_cnt": {
    name: '展示次数',
    unit: '次',
  },//number 展示次数
  "ctr": {
    name: '点击率',
    unit: '%',
  },//float 点击率
  "cpm_platform": {
    name: '平均千次展示费用',
    unit: '元',
  },//float 平均千次展示费用
  "click_cnt": {
    name: '点击次数',
    unit: '次',
  },//number 点击次数
  "pay_order_count": {
    name: '直接成交订单数',
    unit: '单',
  },//number 直接成交订单数
  "pay_order_amount": {
    name: '直接成交金额',
    unit: '元',
  },//float 直接成交金额
  "prepay_and_pay_order_roi": {
    name: '直接支付roi',
    unit: '',
  },//float 直接支付roi
  "create_order_count": {
    name: '直接下单订单数',
    unit: '单',
  },//number 直接下单订单数
  "create_order_amount": {
    name: '直接下单金额',
    unit: '元',
  },//float 直接下单金额
  "create_order_roi": {
    name: '直接下单roi',
    unit: '',
  },//float 直接下单roi
  "prepay_order_count": {
    name: '直接预售订单数',
    unit: '单',
  },//number 直接预售订单数
  "prepay_order_amount": {
    name: '直接预售金额',
    unit: '元',
  },//float 直接预售金额
  "dy_follow": {
    name: '新增粉丝数',
    unit: '人',
  },//number 新增粉丝数
  "convert_cnt": {
    name: '转化数（待下线）',
    unit: '',
  },//number 转化数（待下线）
  "convert_cost": {
    name: '转化成本（待下线）',
    unit: '元',
  },//float 转化成本（待下线）
  "convert_rate": {
    name: '转化率（待下线）',
    unit: '5',
  },//float 转化率（待下线）
  "ecp_convert_rate": {
    name: '转化率',
    unit: '%',
  },//float 转化率
  "ecp_convert_cnt": {
    name: '转化数',
    unit: '',
  },//number 转化数
  "ecp_cpa_platform": {
    name: '转化成本',
    unit: '元',
  },//float 转化成本
  "dy_share": {
    name: '分享次数',
    unit: '次',
  },//number 分享次数
  "dy_comment": {
    name: '评论次数',
    unit: '次',
  },//number 评论次数
  "dy_like": {
    name: '点赞次数',
    unit: '次',
  },//number 点赞次数
  "live_pay_order_cost_per_order": {
    name: '成交客单价',
    unit: '元',
  },//float 成交客单价
  "luban_live_enter_cnt": {
    name: '直播间观看人次',
    unit: '人',
  },//number 直播间观看人次
  "live_watch_one_minute_count": {
    name: '直播间超过1分钟观看人次',
    unit: '人',
  },//number 直播间超过1分钟观看人次
  "live_fans_club_join_cnt": {
    name: '直播间新加团人次',
    unit: '人',
  },//number 直播间新加团人次
  "luban_live_slidecart_click_cnt": {
    name: '直播间查看购物车次数',
    unit: '次',
  },//number 直播间查看购物车次数
  "luban_live_click_product_cnt": {
    name: '直播间商品点击次数',
    unit: '次',
  },//number 直播间商品点击次数
  "luban_live_comment_cnt": {
    name: '直播间评论次数',
    unit: '次',
  },//number 直播间评论次数
  "luban_live_share_cnt": {
    name: '直播间分享次数',
    unit: '次',
  },//number 直播间分享次数
  "luban_live_gift_cnt": {
    name: '直播间打赏次数',
    unit: '次',
  },//number 直播间打赏次数
  "luban_live_gift_amount": {
    name: '直播间音浪收入',
    unit: '元',
  },//float 直播间音浪收入
  "total_play": {
    name: '播放数',
    unit: '',
  },//number 播放数
  "play_duration_3s": {
    name: '3s播放数',
    unit: '',
  },//number 3s播放数
  "play_25_feed_break": {
    name: '25%进度播放数',
    unit: '',
  },//number 25%进度播放数
  "play_50_feed_break": {
    name: '50%进度播放数',
    unit: '',
  },//number 50%进度播放数
  "play_75_feed_break": {
    name: '75%进度播放数',
    unit: '',
  },//number 75%进度播放数
  "play_over": {
    name: '播放完成数',
    unit: '',
  },//number 播放完成数
  "play_over_rate": {
    name: '完播率',
    unit: '%',
  },//float 完播率
  "cpc_platform": {
    name: '平均点击单价',
    unit: '元',
  },//float 平均点击单价
  "deep_convert_cnt": {
    name: '深度转化次数',
    unit: '次',
  },//number 深度转化次数
  "deep_convert_cost": {
    name: '深度转化成本',
    unit: '元',
  },//float 深度转化成本
  "deep_convert_rate": {
    name: '深度转化率',
    unit: '%',
  },//float 深度转化率
  "attribution_convert_cnt": {
    name: '转化数（计费时间）',
    unit: '',
  },//number 转化数（计费时间）
  "attribution_convert_rate": {
    name: '转化率（计费时间）',
    unit: '%',
  },//float 转化率（计费时间）
  "attribution_convert_cost": {
    name: '转化成本（计费时间）',
    unit: '元',
  },//float 转化成本（计费时间）
  "attribution_deep_convert_cnt": {
    name: '深度转化次数（计费时间）',
    unit: '次',
  },//number 深度转化次数（计费时间）
  "attribution_deep_convert_cost": {
    name: '',
    unit: '',
  },//float 深度转化成本（计费时间）
  "attribution_deep_convert_rate": {
    name: '深度转化率（计费时间）',
    unit: '%',
  },//float 深度转化率（计费时间）
  "all_order_create_roi_7days": {
    name: '7日总下单ROI',
    unit: '',
  },//float 7日总下单ROI
  "all_order_pay_roi_7days": {
    name: '7日总支付ROI',
    unit: '',
  },//float 7日总支付ROI
  "all_order_pay_count_7days": {
    name: '7日总成交订单',
    unit: '元',
  },//number 7日总成交订单
  "all_order_pay_gmv_7days": {
    name: '7日总成交金额',
    unit: '元',
  },//float 7日总成交金额
  "pay_order_cost_per_order": {
    name: '直接成交客单价',
    unit: '元',
  },//float 直接成交客单价
  "create_order_coupon_amount": {
    name: '下单智能优惠券金额',
    unit: '元',
  },//float 下单智能优惠券金额
  "pay_order_coupon_amount": {
    name: '成交智能优惠券金额',
    unit: '元',
  },//float 成交智能优惠券金额
  "indirect_order_create_count_7days": {
    name: '间接下单订单数',
    unit: '单',
  },//number 间接下单订单数
  "indirect_order_create_gmv_7days": {
    name: '间接下单金额',
    unit: '元',
  },//float 间接下单金额
  "indirect_order_pay_count_7days": {
    name: '间接成交订单数',
    unit: '单',
  },//number 间接成交订单数
  "indirect_order_pay_gmv_7days": {
    name: '间接成交金额',
    unit: '元',
  },//float 间接成交金额
  "indirect_order_prepay_count_7days": {
    name: '间接预售订单数',
    unit: '单',
  },//number 间接预售订单数
  "indirect_order_prepay_gmv_7days": {
    name: '间接预售金额',
    unit: '元',
  },//float 间接预售金额
  "qianchuan_first_order_cnt": {
    name: '店铺首单新客人数',
    unit: '人',
  },//number 店铺首单新客人数
  "qianchuan_first_order_rate": {
    name: '店铺首单新客订单占比',
    unit: '元',
  },//float 店铺首单新客订单占比
  "qianchuan_first_order_convert_cost": {
    name: '店铺首单新客转化成本',
    unit: '元',
  },//float 店铺首单新客转化成本
  "qianchuan_first_order_direct_pay_gmv": {
    name: '店铺首单新客直接成交金额',
    unit: '元',
  },//float 店铺首单新客直接成交金额
  "qianchuan_first_order_direct_pay_order_roi": {
    name: '店铺首单新客直接支付ROI',
    unit: '',
  },//float 店铺首单新客直接支付ROI
  "qianchuan_first_order_ltv30": {
    name: '店铺首单新客30天累计成交金额',
    unit: '元',
  },//float 店铺首单新客30天累计成交金额
  "qianchuan_first_order_roi30": {
    name: '店铺首单新客30天累计支付ROI',
    unit: '',
  },//float 店铺首单新客30天累计支付ROI
  "qianchuan_brand_first_order_rate": {
    name: '品牌首单新客订单占比',
    unit: '%',
  },//float 品牌首单新客订单占比
  "qianchuan_brand_first_order_convert_cost": {
    name: '品牌首单新客转化成本',
    unit: '元',
  },//float 品牌首单新客转化成本
  "qianchuan_brand_first_order_ltv30": {
    name: '品牌首单新客30天累计成交金额',
    unit: '元',
  },//float 品牌首单新客30天累计成交金额
  "qianchuan_brand_first_order_direct_pay_order_roi": {
    name: '品牌首单新客直接支付roi',
    unit: '',
  },//float 品牌首单新客直接支付roi
  "qianchuan_brand_first_order_roi30": {
    name: '品牌首单新客30天累计支付roi',
    unit: '',
  },//float 品牌首单新客30天累计支付roi
  "qianchuan_brand_first_order_cnt": {
    name: '品牌首单新客数',
    unit: '人',
  },//number 品牌首单新客数
  "qianchuan_brand_first_order_direct_pay_gmv": {
    name: '品牌首单新客直接成交金额',
    unit: '元',
  },//float 品牌首单新客直接成交金额
  "qianchuan_author_first_order_rate": {
    name: '抖音号首单新客订单占比',
    unit: '%',
  },//float 抖音号首单新客订单占比
  "qianchuan_author_first_order_roi30": {
    name: '抖音号首单新客30天累计支付roi',
    unit: '',
  },//float 抖音号首单新客30天累计支付roi
  "qianchuan_author_first_order_cnt": {
    name: '抖音号首单新客数',
    unit: '单',
  },//number 抖音号首单新客数
  "qianchuan_author_first_order_convert_cost": {
    name: '抖音号首单新客转化成本',
    unit: '',
  },//float 抖音号首单新客转化成本
  "qianchuan_author_first_order_direct_pay_gmv": {
    name: '抖音号首单新客直接成交金额',
    unit: '元',
  },//float 抖音号首单新客直接成交金额
  "qianchuan_author_first_order_direct_pay_order_roi": {
    name: '抖音号首单新客直接支付roi',
    unit: '',
  },//float 抖音号首单新客直接支付roi
  "qianchuan_author_first_order_ltv30": {
    name: '抖音号首单新客30天累计成交金额',
    unit: '元',
  },//float 抖音号首单新客30天累计成交金额
  "unfinished_estimate_order_gmv": {
    name: '未完结直接预售订单预估金额',
    unit: '元',
  },//float 未完结直接预售订单预估金额
  "indirect_order_unfinished_estimate_gmv_7days": {
    name: '未完结间接预售订单预估金额',
    unit: '元',
  },//float 未完结间接预售订单预估金额
}

//广告素材数据key
export const adMaterialKey = {
  "stat_cost": {
    name: '消耗',
    unit: '元',
  },//double 消耗
  "show_cnt": {
    name: '展示次数',
    unit: '次',
  },//double 展示次数
  "ctr": {
    name: '点击率',
    unit: '%',
  },//double 点击率
  "cpm_platform": {
    name: '平均千次展现费用',
    unit: '',
  },//double 平均千次展现费用
  "click_cnt": {
    name: '点击次数',
    unit: '次',
  },//double 点击次数
  "convert_cnt": {
    name: '转化数（待下线）',
    unit: '',
  },//double 转化数（待下线）
  "cpa_platform": {
    name: '转化成本（待下线）',
    unit: '元',
  },//double 转化成本（待下线）
  "convert_rate": {
    name: '转化率（待下线）',
    unit: '%',
  },//double 转化率（待下线）
  "material_arpu": {
    name: '素材ARPU',
    unit: '',
  },//double 素材ARPU
  "pay_order_count": {
    name: '直接成交订单数',
    unit: '单',
  },//double 直接成交订单数
  "pay_order_amount": {
    name: '直接成交金额',
    unit: '元',
  },//double 直接成交金额
  "prepay_and_pay_order_roi": {
    name: '直接支付ROI',
    unit: '',
  },//double 直接支付ROI
  "create_order_count": {
    name: '直接下单订单数',
    unit: '单',
  },//double 直接下单订单数
  "create_order_amount": {
    name: '直接下单金额',
    unit: '元',
  },//double 直接下单金额
  "create_order_roi": {
    name: '直接下单ROI',
    unit: '',
  },//double 直接下单ROI
  "prepay_order_count": {
    name: '直接预售订单数',
    unit: '单',
  },//double 直接预售订单数
  "prepay_order_amount": {
    name: '直接预售金额',
    unit: '元',
  },//double 直接预售金额
  "dy_follow": {
    name: '新增粉丝数',
    unit: '人',
  },//double 新增粉丝数
  "luban_live_enter_cnt": {
    name: '直播间观看人次',
    unit: '人',
  },//double 直播间观看人次
  "live_watch_one_minute_count": {
    name: '直播间超过1分钟观看人次',
    unit: '人',
  },//double 直播间超过1分钟观看人次
  "live_fans_club_join_cnt": {
    name: '直播间新加团人次',
    unit: '人',
  },//double 直播间新加团人次
  "luban_live_slidecart_click_cnt": {
    name: '直播间查看购物车次数',
    unit: '次',
  },//double 直播间查看购物车次数
  "luban_live_click_product_cnt": {
    name: '直播间商品点击次数',
    unit: '次',
  },//double 直播间商品点击次数
  "luban_live_comment_cnt": {
    name: '直播间评论次数',
    unit: '次',
  },//double 直播间评论次数
  "luban_live_share_cnt": {
    name: '直播间分享次数',
    unit: '次',
  },//double 直播间分享次数
  "luban_live_gift_cnt": {
    name: '直播间打赏次数',
    unit: '次',
  },//double 直播间打赏次数
  "luban_live_gift_amount": {
    name: '直播间音浪收入',
    unit: '',
  },//double 直播间音浪收入
  "dy_share": {
    name: '分享次数',
    unit: '次',
  },//double 分享次数
  "dy_comment": {
    name: '评论次数',
    unit: '次',
  },//double 评论次数
  "dy_like": {
    name: '点赞次数',
    unit: '次',
  },//double 点赞次数
  "total_play": {
    name: '播放数',
    unit: '',
  },//double 播放数
  "play_duration_2s_rate": {
    name: '2s播放率',
    unit: '%',
  },//double 2s播放率
  "play_duration_3s_rate": {
    name: '3s播放率',
    unit: '%',
  },//double 3s播放率
  "play_duration_5s_rate": {
    name: '5s播放率',
    unit: '%',
  },//double 5s播放率
  "play_duration_10s_rate": {
    name: '10s播放率',
    unit: '%',
  },//double 10s播放率 
  "play_25_feed_break": {
    name: '25%进度播放数',
    unit: '',
  },//double 25%进度播放数
  "play_50_feed_break": {
    name: '50%进度播放数',
    unit: '',
  },//double 50%进度播放数
  "play_75_feed_break": {
    name: '75%进度播放数',
    unit: '',
  },//double 75%进度播放数
  "play_over": {
    name: '播放完成数',
    unit: '',
  },//double 播放完成数
  "play_over_rate": {
    name: '完播率',
    unit: '%',
  },//double 完播率
  "valid_play": {
    name: '有效播放数',
    unit: '',
  },//double 有效播放数
  "average_play_time_per_play": {
    name: '平均单次播放时长',
    unit: '',
  },//double 平均单次播放时长
  "valid_play_rate": {
    name: '有效播放率',
    unit: '%',
  },//double 有效播放率
  "dislike_cnt": {
    name: '不感兴趣数',
    unit: '',
  },//double 不感兴趣数
  "report_cnt": {
    name: '举报数',
    unit: '',
  },//double 举报数
  "ecp_convert_rate": {
    name: '转化率',
    unit: '%',
  },//float 转化率
  "ecp_convert_cnt": {
    name: '转化数',
    unit: '',
  },//float 转化数
  "ecp_cpa_platform": {
    name: '转化成本',
    unit: '元',
  },//float 转化成本
  "unfinished_estimate_order_gmv": {
    name: '未完结直接预售订单预估金额',
    unit: '元',
  },//float 未完结直接预售订单预估金额
  "indirect_order_unfinished_estimate_gmv_7days": {
    name: '未完结间接预售订单预估金额',
    unit: '元',
  },//float 未完结间接预售订单预估金额
  "pay_order_coupon_amount": {
    name: '成交智能优惠券金额',
    unit: '元',
  },//float 成交智能优惠券金额
  "create_order_coupon_amount": {
    name: '下单智能优惠券金额',
    unit: '元',
  },//number 下单智能优惠券金额
}

//广告关键词/搜索词
export const adSearchAndKeywordsKey = {
  "start_date": {
    name: '开始日期',
    unit: '',
  },//string 开始日期
  "end_date": {
    name: '',
    unit: '结束日期',
  },//string 结束日期
  "search_word": {
    name: '搜索词',
    unit: '',
  },//string 搜索词
  "key_word": {
    name: '关键词',
    unit: '',
  },//string 关键词
  "keyword_match_type": {
    name: '触发（匹配）方式',
    unit: '',
  },//string 触发（匹配）方式
  "ad_id": {
    name: '所属计划',
    unit: '',
  },//number 所属计划
  "ad_name": {
    name: '计划名称',
    unit: '',
  },//string 计划名称
  "campaign_id": {
    name: '所属广告组',
    unit: '',
  },//number 所属广告组
  "campaign_name": {
    name: '广告组名称',
    unit: '',
  },//string 广告组名称
  "stat_cost": {
    name: '消耗',
    unit: '元',
  },//number 消耗
  "show_cnt": {
    name: '展示次数',
    unit: '',
  },//number 展示次数
  "ctr": {
    name: '点击率',
    unit: '%',
  },//number 点击率
  "click_cnt": {
    name: '点击次数',
    unit: '',
  },//number 点击次数
  "cpm_platform": {
    name: '平均千次展现费用',
    unit: '元',
  },//number 平均千次展现费用
  "ecp_convert_cnt": {
    name: '转化数',
    unit: '',
  },//number 转化数
  "ecp_convert_rate": {
    name: '转化率',
    unit: '%',
  },//number 转化率
  "ecp_cpa_platform": {
    name: '转化成本',
    unit: '元',
  },//number 转化成本
  "pay_order_count": {
    name: '直接成交订单数',
    unit: '',
  },//number 直接成交订单数
  "pay_order_amount": {
    name: '直接成交金额',
    unit: '元',
  },//number 直接成交金额
  "prepay_and_pay_order_roi": {
    name: '直接支付ROI',
    unit: '',
  },//number 直接支付ROI
  "create_order_count": {
    name: '直接下单订单数',
    unit: '',
  },//number 直接下单订单数
  "create_order_amount": {
    name: '直接下单金额',
    unit: '元',
  },//number 直接下单金额
  "create_order_roi": {
    name: '直接下单ROI',
    unit: '',
  },//number 直接下单ROI
  "prepay_order_count": {
    name: '直接预售订单数',
    unit: '',
  },//number 直接预售订单数
  "prepay_order_amount": {
    name: '直接预售金额',
    unit: '元',
  },//number 直接预售金额
  "pay_order_cost_per_order": {
    name: '直接成交客单价',
    unit: '元',
  },//number 直接成交客单价
  "dy_follow": {
    name: '新增粉丝数',
    unit: '',
  },//number 新增粉丝数
  "luban_live_enter_cnt": {
    name: '直播间观看人次',
    unit: '',
  },//number 直播间观看人次
  "live_watch_one_minute_count": {
    name: '直播间超过1分钟观看人次',
    unit: '',
  },//number 直播间超过1分钟观看人次
  "live_fans_club_join_cnt": {
    name: '直播间新加团人次',
    unit: '',
  },//number 直播间新加团人次
  "luban_live_slidecart_click_cnt": {
    name: '直播间查看购物车次数',
    unit: '',
  },//number 直播间查看购物车次数
  "luban_live_click_product_cnt": {
    name: '直播间商品点击次数',
    unit: '',
  },//number 直播间商品点击次数
  "luban_live_comment_cnt": {
    name: '直播间评论次数',
    unit: '',
  },//number 直播间评论次数
  "luban_live_share_cnt": {
    name: '直播间分享次数',
    unit: '',
  },//number 直播间分享次数
  "luban_live_gift_cnt": {
    name: '直播间打赏次数',
    unit: '',
  },//number 直播间打赏次数
  "luban_live_gift_amount": {
    name: '直播间音浪收入',
    unit: '',
  },//number 直播间音浪收入
  "dy_share": {
    name: '分享次数',
    unit: '',
  },//number 分享次数
  "dy_comment": {
    name: '评论次数',
    unit: '',
  },//number 评论次数
  "dy_like": {
    name: '点赞次数',
    unit: '',
  },//number 点赞次数
  "total_play": {
    name: '播放数',
    unit: '',
  },//number 播放数
  "play_duration_3s": {
    name: '3秒播放数',
    unit: '',
  },//number 3秒播放数
  "play_25_feed_break": {
    name: '25%进度播放数',
    unit: '',
  },//number 25%进度播放数
  "play_50_feed_break": {
    name: '50%进度播放数',
    unit: '',
  },//number 50%进度播放数
  "play_75_feed_break": {
    name: '75%进度播放数',
    unit: '',
  },//number 75%进度播放数
  "play_over": {
    name: '播放完成数',
    unit: '',
  },//number 播放完成数
  "play_over_rate": {
    name: '完播率',
    unit: '%',
  },//number 完播率
}

//广告视频流失数据
export const lostKey = {
  click_cnt: '点击数',
  dy_follow: '关注数',
  dy_comment: '评论数',
  dy_like: '点赞数',
  user_lose_cnt: '流失数',
}

//
export const flotSourceKey = {
  'ACTIVITY': '直播-活动页面',
  'DOUYIN_SHOPPING_CENTER': '直播-抖音商城',
  'GENERAL_SEARCH': '直播-搜索',
  'GUESS_YOU_LIKE': '短视频-猜你喜欢',
  'HOMEPAGE_FOLLOW': '直播-关注来源',
  'LIVE': '直播',
  'LIVE_OTHER': '直播-其他',
  'OTHERS_HOMEPAGE': '直播-个人主页',
  'OTHER_PROFILE': '短视频-达人个人主页',
  'PRODUCT_CARD': '商品卡',
  'PRODUCT_CARD_GENERAL_SEARCH': '商户卡-搜索',
  'PRODUCT_CARD_OTHER': '商户卡-购物车/收藏',
  'QIANCHUAN_PROMOTE': '千川推广',
  'RECOMMEND_LIVE': '直播-自然推荐',
  'RECOMMEND_PRODUCT': '商户卡-商城/商品推荐',
  'RECOMMEND_VIDEO': '短视频-短视频推荐',
  'SHOP_WINDOW': '商户卡-橱窗/店铺',
  'UNKNOWN': '未知',
  'VIDEO': '短视频',
  'VIDEO_ACTIVITY': '短视频-活动页',
  'VIDEO_GENERAL_SEARCH': '短视频-搜索',
  'VIDEO_HOMEPAGE_FOLLOW': '短视频-关注来源',
  'VIDEO_OTHER': '短视频-其他',
  'VIDEO_TO_LIVE': '直播-短视频引流',
}

const ssoInfoKey = "ssoInfo";
const SSODomain = "mhcwj.com";

export function getSSOInfo() {
  const encrypt = Cookies.get(ssoInfoKey);
  return encrypt ? JSON.parse(atob(encrypt)) : encrypt;
}

export function setSSOInfo(ssoInfo) {
  const encrypt = btoa(JSON.stringify(ssoInfo));
  return Cookies.set(ssoInfoKey, encrypt, {
    domain: SSODomain,
  });
}

export function removeSSOInfo() {
  return Cookies.remove(ssoInfoKey, {
    domain: SSODomain,
  });
}

const TokenKey = "Admin-Token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUrlParams(url, name) {
  let u = url.substring(url.indexOf("?") + 1);
  let array = u.split("&");
  for (let i = 0; i < array.length; i++) {
    let item = array[i];
    if (item.startsWith(name)) {
      let b = item.split("=");
      return b[1];
    }
  }
  return "";
}