import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "default-passive-events";
import CustomstaticsSource from "@/components/Commones/CustomstaticsSource.vue";
import Customstatistics from "@/components/Commones/Customstatistics.vue";
import Empty from "@/components/Empty/Empty.vue";

createApp(App)
  .use(store)
  .use(router)
  .component("CustomstaticsSource", CustomstaticsSource)
  .component("Customstatistics", Customstatistics)
  .component("Empty", Empty)
  .mount("#app");
