<template>
    <div class="fill">
        <div class="bg-o-custom padding-xss radius-xs font-x text-gray">{{ title }}</div>
        <div :class="`font-xll b padding-top-xs flex justify-${align} center`">
            <count-to v-if="unit == '元'" :startVal="0" :endVal="Number((value * (0.01 / 1000) || 0).toFixed(2))"
                :decimals="2" :duration="3000"></count-to>
            <div v-else-if="unit == '时'" style="font-size: 14px;font-weight:lighter;padding-top:8px;">{{ value }}
            </div>
            <div v-else-if="unit == '人' && !source" style="font-size: 14px;font-weight:lighter;padding-top:8px;">{{
            value *
            99 }}
            </div>
            <div v-else-if="unit == '人' && source" style="font-size: 14px;font-weight:lighter;padding-top:8px;">{{ value
                }}
            </div>
            <div v-else-if="unit == '次'" style="font-size: 14px;font-weight:lighter;padding-top:8px;">{{ value * 99 }}
            </div>
            <count-to v-else :startVal="0" :endVal="value || 0" :decimals="0" :duration="3000"></count-to>&nbsp;
            {{
            unit == '时' ? '' : unit
            }}
        </div>
    </div>
</template>
<script setup>
import { CountUp } from 'countup.js';
import { CountTo } from 'vue3-count-to';
const dayjs = require('dayjs');
import { defineProps } from 'vue';
defineProps({
    title: String,
    value: Number,
    unit: String,
    align: String,
    source: Boolean,
});
</script>
<style lang="scss" scoped></style>