<template>
    <div class="fill">
        <div class="bg-o-custom padding-xss radius-xs font-x text-gray">{{ title }}</div>
        <div :class="`font-xll b padding-top-xs flex justify-${align} center`">
            <div v-if="unit == '元'">
                <count-to v-if="un == 'big'" :startVal="0"
                    :endVal="Number((value * (0.01 / 1000) * 9999 || 0).toFixed(2))" :decimals="2"
                    :duration="3000"></count-to>
                <count-to v-if="un == 'no'" :startVal="0"
                    :endVal="Number((value * (0.01 / 1000) * 999 || 0).toFixed(2))" :decimals="2"
                    :duration="3000"></count-to>
                <count-to v-if="un == 'rand'" :startVal="0"
                    :endVal="Number((value * (0.01 / 1000) * 99 || 0).toFixed(2))" :decimals="2"
                    :duration="3000"></count-to>
                <count-to v-if="un == 'three'" :startVal="0" :endVal="Number((value * (0.01 / 1000) || 0).toFixed(2))"
                    :decimals="2" :duration="3000"></count-to>
                <count-to v-if="un == 'a'" :startVal="0"
                    :endVal="Number((value * (0.01 / 1000) + 9999999 || 0).toFixed(2))" :decimals="2"
                    :duration="3000"></count-to>
                <count-to v-if="un == 'ex'" :startVal="0" :endVal="Number(value.toFixed(2))" :decimals="2"
                    :duration="3000"></count-to>
            </div>
            <div v-else-if="unit == '时'" style="font-size: 14px;font-weight:lighter;padding-top:8px;">{{ (Number(value)
            * 100) || 1000 }}
            </div>
            <div v-else-if="unit == '%'">
                <div v-if="ni == 'a'">{{ Number(value < 10 ? (Math.floor(Math.random() * (5 - 3 + 1)) + 3) :
            value).toFixed(0) }}.{{ Math.floor(Math.random() * 99).toFixed(0) }}</div>
                        <div v-if="ni == 'b'">{{ Number(value < 20 ? (Math.floor(Math.random() * (40 - 30 + 1)) + 30) :
            value).toFixed(0) }}.{{ Math.floor(Math.random() * 99).toFixed(0) }}</div>
                                <div v-if="ni == 'y'">{{ Number(value < 60 ? (Math.floor(Math.random() * (80 - 60 + 1))
            + 60) : value).toFixed(0) }}.{{ Math.floor(Math.random() * 99).toFixed(0)
                                        }}</div>
                                </div>
                                <div v-else-if="unit == '场' && un != 'ex'">{{ value }}</div>
                                <div v-else-if="unit == 'string' && un != 'ex'">{{ value }}</div>
                                <count-to v-else-if="un == 'ex'" :startVal="0" :endVal="value || 0" :decimals="0"
                                    :duration="3000"></count-to>
                                <count-to v-else :startVal="0" :endVal="value * 99 || 0" :decimals="0"
                                    :duration="3000"></count-to>&nbsp;
                                {{ unit == '时' || unit == 'string' ? '' : unit }}
                        </div>
                </div>
</template>
<script setup>
import { CountUp } from 'countup.js';
import { CountTo } from 'vue3-count-to';
const dayjs = require('dayjs');
import { defineProps } from 'vue';
defineProps({
    title: String,
    value: Number,
    unit: String,
    align: String,
    un: String,
    ni: String,
});
</script>
<style lang="scss" scoped></style>
