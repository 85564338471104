import { createRouter, createWebHistory } from "vue-router";
import { https } from "@/assets/incs/https";
import { apis } from "@/assets/incs/apis";
import { getSSOInfo, getToken, setToken, setSSOInfo, removeSSOInfo, notices, getUrlParams } from "@/assets/incs/common";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/author",
    name: "author",
    component: () => import("../views/AuthorView.vue"),
  }, {
    path: "/error",
    name: "error",
    component: () => import("../views/ErrorView.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const ssoInfo = getSSOInfo();
  const accessToken = getToken();
  const outAuthCode = getUrlParams(to.fullPath, 'authCode');
  const outTenantId = getUrlParams(to.fullPath, 'authTenantId');
  if (ssoInfo && !accessToken) {
    //单点登录请求
    const res = await https(apis.api_sso_login, {
      method: "POST",
      params: { ...ssoInfo }
    });
    if (res.data.code == 200) {
      setToken(res.data.data.token);
      setSSOInfo(res.data.data);
      if (!router.hasRoute(to.name)) {
        //请求动态路由
        const ress = await https(apis.api_menus, {
          method: "GET",
          params: { scopeStatus: true },
        });
        localStorage.setItem("menuSource", JSON.stringify(ress.data.data));
        ress.data.data.forEach((ele) => {
          router.addRoute("main", {
            name: ele.routeName,
            path: ele.routePath,
            component: () => import(`@/components/${ele.routeComponent}`),
          });
        });
        if (ress.data.data.length > 0) {
          next({ path: ress.data.data[0].routePath, replace: true });
        } else {
          next({ path: '/error', replace: true });
        }
      } else {
        const ress = await https(apis.api_menus, {
          method: "GET",
          params: { scopeStatus: true },
        });
        if (to.path == "/login" || to.path == "/") {
          // next({ path: '/flow' });
          if (ress.data.data.length > 0) {
            next({ path: ress.data.data[0].routePath, replace: true });
          } else {
            next({ path: '/error', replace: true });
          }
        } else {
          next();
        }
      }
    } else {
      notices('error', '请求错误~');
    }
  } else if ((to.query && to.query.authCode && to.query.authTenantId) || (outAuthCode && outTenantId)) {
    const res = await https(apis.api_sso_login, {
      method: "POST",
      params: {
        grantCode: to.query.authCode || outAuthCode,
        tenantId: to.query.authTenantId || outTenantId,
        shortToken: to.query.shortToken || '',
        longToken: to.query.longToken || '',
        expiresIn: to.query.expiresIn || '',
      }
    });
    if (res.data.code == 200) {
      localStorage.removeItem('routerPath');
      localStorage.removeItem('menuKey');
      setToken(res.data.data.token);
      setSSOInfo(res.data.data);
      if (!router.hasRoute(to.name)) {
        //请求动态路由
        const ress = await https(apis.api_menus, {
          method: "GET",
          params: { scopeStatus: true },
        });
        localStorage.setItem("menuSource", JSON.stringify(ress.data.data));
        ress.data.data.forEach((ele) => {
          router.addRoute("main", {
            name: ele.routeName,
            path: ele.routePath,
            component: () => import(`@/components/${ele.routeComponent}`),
          });
        });
        if (ress.data.data.length > 0) {
          next({ path: ress.data.data[0].routePath, replace: true });
        } else {
          next({ path: '/error', replace: true });
        }
      } else {
        const ress = await https(apis.api_menus, {
          method: "GET",
          params: { scopeStatus: true },
        });
        if (to.path == "/login" || to.path == "/") {
          // next({ path: '/flow' });
          if (ress.data.data.length > 0) {
            next({ path: ress.data.data[0].routePath, replace: true });
          } else {
            next({ path: '/error', replace: true });
          }
        } else {
          next();
        }
      }
    } else {
      notices('error', '请求错误~');
    }
  } else if (accessToken) {
    if (!router.hasRoute(to.name)) {
      //请求动态路由
      const res = await https(apis.api_menus, {
        method: "GET",
        params: { scopeStatus: true },
      });
      localStorage.setItem("menuSource", JSON.stringify(res.data.data));
      res.data.data.forEach((ele) => {
        router.addRoute("main", {
          name: ele.routeName,
          path: ele.routePath,
          component: () => import(`@/components/${ele.routeComponent}`),
        });
      });
      if (res.data.data.length > 0) {
        next({ path: res.data.data[0].routePath, replace: true });
      } else {
        next({ path: '/error', replace: true });
      }
    } else {
      const res = await https(apis.api_menus, {
        method: "GET",
        params: { scopeStatus: true },
      });
      if (to.path == "/login" || to.path == "/") {
        // next({ path: '/flow' });
        if (res.data.data.length > 0) {
          next({ path: res.data.data[0].routePath, replace: true });
        } else {
          next({ path: '/error', replace: true });
        }
      } else {
        next();
      }
    }
  } else if (to.path == "/flow") {
    next();
  } else {
    // window.location.href = 'https://222.220.55.29:8443/mhmain';
    const res = await https(apis.api_get_sso_url, {
      method: "GET",
      params: {
        redirectUrl: window.location.origin + '/login'
      }
    })
    if (res.data.code == 200) {
      location.href = res.data.data;
    } else {
      notices('error', '请求错误~');
    }
  }
});

export default router;
