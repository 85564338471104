<template>
  <router-view />
</template>

<script setup>

</script>

<style lang="scss">
@import url('@/assets/csses/common.css');
@import url('boxicons');
</style>
